import React from 'react';

import ImageRater from './components/ImageRater';

function App() {
  return (
    <div className="App">
      <ImageRater/>
    </div>
  );
}

export default App;
