import { CheckIcon } from '@heroicons/react/24/outline';

export default function InfoDialog({ setConsentGiven }: { setConsentGiven: (value: boolean) => void }) {

    return (
        <>
            {/* Overlay with blur effect */}
            <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm"></div>

            {/* Modal box centered */}
            <div className="fixed inset-0 flex items-center justify-center">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Hi 🖖</h3>
                    <p>We are on a quest to find out if AI can generate good hentai 🫦. The first step is to find out which of the generations you like 🚫/💚.</p>
                    <br></br>
                    <p>Please confirm you are over 18 years old. This page contains explicit content not suited for minors 🔞</p>
                    <div className="modal-action">
                        <form method="dialog">
                            <button onClick={() => setConsentGiven(true)} className="px-1 py-1 mr-3 outline-purple-500 text-gray-700 outline outline-2 rounded">
                                <CheckIcon className="h-9 w-9" />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}