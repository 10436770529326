import React, { useState, useEffect, useRef } from 'react';
import { HeartIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import InfoDialog from './InfoDialog';

import loadingImg from '../assets/loading.png';
import endImg from '../assets/end.png';

interface Image {
    id: string;
}

export default function ImageRater() {
    const modalRef = useRef<HTMLDialogElement>(null);
    const [imageIds, setImageIds] = useState<Image[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [consentGiven, setConsentGiven] = useState(false);
    const [continuationToken, setContinuationToken] = useState<string>()

    const [hasMore, setHasMore] = useState(true);


    useEffect(() => {
        modalRef.current?.showModal();
        fetchImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchImages = async () => {
        console.log(continuationToken)
        await fetch("/api/GetImages", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ continuationToken: continuationToken })
        }).then(async r => {
            if (r.status === 200) {
                let d = await r.json()
                setImageIds([...imageIds, ...d.items]);
                setContinuationToken(d.nextPageToken);
                setHasMore(d.hasMoreItems);
            };
        }).catch(e => {
            console.error(e);
        });
    };

    const vote = async (id: string, up: boolean) => {
        await fetch("/api/putVote", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: id, up: up })
        });
    }

    const onLikeClick = () => {
        setCurrentIndex(currentIndex + 1);
        vote(imageIds[currentIndex].id, true)
    };

    const onDislikeClick = () => {
        vote(imageIds[currentIndex].id, false)
        setCurrentIndex(currentIndex + 1);
    };

    useEffect(() => {
        if (imageIds.length - currentIndex === 1) {
            fetchImages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex, imageIds.length]);

    return (
        <>
            <dialog className="modal" ref={modalRef}>
                <InfoDialog setConsentGiven={setConsentGiven} />
            </dialog>
            <div className="flex flex-col h-screen overflow-hidden">
                <div className="flex-grow flex justify-center items-center m-2 overflow-hidden">
                    {(imageIds.length > 0 && consentGiven) && (
                        <img
                            src={`https://hentkiart.blob.core.windows.net/images/${imageIds[currentIndex].id}.png`}
                            alt=""
                            className="max-h-full max-w-full object-scale-down rounded-lg border-2 border-purple-400"
                        />
                    )}
                    {(imageIds.length === 0 || !consentGiven) && (
                        <img
                            src={loadingImg}
                            alt=""
                            className="max-h-full max-w-full object-scale-down rounded-lg border-2 border-purple-400"
                        />
                    )}
                    {(hasMore === false) && (
                        <img
                            src={endImg}
                            alt=""
                            className="max-h-full max-w-full object-scale-down rounded-lg border-2 border-purple-400"
                        />
                    )}
                    </div>
                <div className="flex-grow flex justify-center items-center mb-2">
                    <div className="flex space-x-32">
                        <button
                            className="border-2 border-red-400 text-red-400 p-4 rounded-full w-16 h-16 flex items-center justify-center focus:outline-none hover:bg-red-100 hover:text-white transition-colors duration-150"
                            onClick={onDislikeClick}
                        >
                            <NoSymbolIcon className="h-6 w-6" />
                        </button>
                        <button
                            className="border-2 border-green-400 text-green-400 p-4 rounded-full w-16 h-16 flex items-center justify-center focus:outline-none hover:bg-green-100 hover:text-white transition-colors duration-150"
                            onClick={onLikeClick}
                        >
                            <HeartIcon className="h-6 w-6" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}